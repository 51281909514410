import styled from "styled-components"
import { EnvelopeSquare } from "@styled-icons/fa-solid/EnvelopeSquare"

const EnvelopeIcon = styled(EnvelopeSquare)`
  color: ${props => props.theme.colors.text.darkGrey};
  height: 30px;
  margin: 0 8px;
`

export default EnvelopeIcon
