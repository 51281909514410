import React, { useState, useContext, useEffect } from "react"
import { motion } from "framer-motion"
import Context from "../../../store/Context"
import { Link } from "gatsby"

//Logo SVG import
import Logo from "../../../images/logo-mobile.svg"

//Styled components imports
import Wrapper from "./Wrapper"
import MenuIconWrapper from "./MenuIconWrapper"

//React components imports
import MenuOverlay from "./MenuOverlay"

const MobileNavbar = () => {
  const { state, dispatch } = useContext(Context)
  const [isInitial, setIsInitial] = useState(true)

  useEffect(() => {
    if (state.menuOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [state.menuOpen])

  const lineOneVariants = {
    hamburger: { rotate: 0, y: 0 },
    cross: { rotate: 45, y: 6 },
  }

  const lineTwoVariants = {
    hamburger: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    cross: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
  }

  const lineThreeVariants = {
    hamburger: {
      rotate: 0,
      y: 0,
    },
    cross: {
      rotate: -45,
      y: -6,
    },
  }

  return (
    <>
      {state.menuOpen ? <MenuOverlay /> : <MenuOverlay hidden />}
      <Wrapper>
        <Link to="/">
          <Logo />
        </Link>
        <MenuIconWrapper>
          <svg
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
              setIsInitial(false)
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              d="M3 12H21"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              variants={lineTwoVariants}
              initial={
                isInitial ? false : state.menuOpen ? "hamburger" : "cross"
              }
              animate={state.menuOpen ? "cross" : "hamburger"}
            />
            <motion.path
              d="M3 6H21"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              variants={lineOneVariants}
              initial={
                isInitial ? false : state.menuOpen ? "hamburger" : "cross"
              }
              animate={state.menuOpen ? "cross" : "hamburger"}
            />
            <motion.path
              d="M3 18H21"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              variants={lineThreeVariants}
              initial={
                isInitial ? false : state.menuOpen ? "hamburger" : "cross"
              }
              animate={state.menuOpen ? "cross" : "hamburger"}
            />
          </svg>
        </MenuIconWrapper>
      </Wrapper>
    </>
  )
}

export default MobileNavbar
