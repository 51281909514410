import React from "react"
import { Media } from "../../utils/Media"

//React components imports
import MobileNavbar from "./MobileNavbar"
import DesktopNavbar from "./DesktopNavbar"

//Styled components imports
import StyledHeader from "./StyledHeader"

const Header = () => {
  return (
    <StyledHeader id="header">
      <Media lessThan="largeDesktop">
        <MobileNavbar />
      </Media>
      <Media greaterThanOrEqual="largeDesktop">
        <DesktopNavbar />
      </Media>
    </StyledHeader>
  )
}

export default Header
