import styled from "styled-components"
import { motion } from "framer-motion"

const Wrapper = styled(motion.nav)`
  background-color: ${props => props.theme.colors.primary.darkGrey};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: auto;
`

export default Wrapper
