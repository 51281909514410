import styled from "styled-components"
import { FacebookSquare } from "@styled-icons/fa-brands/FacebookSquare"

const FacebookIcon = styled(FacebookSquare)`
  color: ${props => props.theme.colors.text.darkGrey};
  height: 30px;
  margin: 0 8px;
`

export default FacebookIcon
