import React, { useContext } from "react"
import Context from "../../../store/Context"
import scrollTo from "gatsby-plugin-smoothscroll"

//Styled components imports
import Wrapper from "./Wrapper"
import NavLinks from "./NavLinks"
import NavLink from "./NavLink"
import Link from "./Link"

const DesktopNavbar = () => {
  const { state, dispatch } = useContext(Context)

  const handleClick = e => {
    e.target.id && scrollTo(e.target.id)
    dispatch({ type: "SET_PATH", path: "/" + e.target.id })
  }

  return (
    <Wrapper>
      <NavLinks>
        <NavLink
          active={
            state.path === "/" || state.path.startsWith("/#")
              ? "true"
              : undefined
          }
          onClick={handleClick}
        >
          <Link to="/" onClick={handleClick}>
            Úvod
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/#ubytovanie" onClick={handleClick} id="#ubytovanie">
            Ubytovanie
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/#jedalen" onClick={handleClick} id="#jedalen">
            Kaviareň a jedáleň
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/#cennik" onClick={handleClick} id="#cennik">
            Cenník
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/#kontakt" onClick={handleClick} id="#kontakt">
            Kontakt
          </Link>
        </NavLink>
        <NavLink active={state.path === "/galeria" ? "true" : undefined}>
          <Link to="/galeria">Galéria</Link>
        </NavLink>

        <NavLink>
          <Link
            to="/prihlasenie"
            highlighted="true"
            id="rezervacia"
            onClick={handleClick}
          >
            Rezervácia
          </Link>
        </NavLink>
      </NavLinks>
    </Wrapper>
  )
}

export default DesktopNavbar
