import React, { useContext } from "react"
import Context from "../../../../store/Context"

//Styled components imports
import Wrapper from "./Wrapper"
import NavLinks from "./NavLinks"
import NavLink from "./NavLink"
import Link from "./Link"

const MenuOverlay = props => {
  const { dispatch } = useContext(Context)
  const variants = {
    open: {
      x: 0,
      transition: {
        type: "tween",
      },
    },
    closed: { x: "100%", transition: { type: "tween" } },
  }

  return (
    <Wrapper
      initial="closed"
      animate={props.hidden ? "closed" : "open"}
      variants={variants}
    >
      <NavLinks>
        <NavLink>
          <Link
            to="/"
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
            }}
          >
            Úvod
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/#ubytovanie"
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
            }}
          >
            Ubytovanie
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/#cennik"
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
            }}
          >
            Cenník
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/#jedalen"
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
            }}
          >
            Kaviareň a jedáleň
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/#kontakt"
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
            }}
          >
            Kontakt
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/galeria"
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
            }}
          >
            Galéria
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/prihlasenie"
            highlighted
            onClick={() => {
              dispatch({ type: "TOGGLE_MENU" })
            }}
          >
            Rezervácia
          </Link>
        </NavLink>
      </NavLinks>
    </Wrapper>
  )
}

export default MenuOverlay
