import React from "react"
import { ThemeProvider } from "styled-components"

//Global styles import (styled-components)
import GlobalStyle from "../../utils/styled-components/globalStyles"
//Theme import (styled-components)
import theme from "../../utils/styled-components/theme"

//React components imports
import Header from "../Header"
import Footer from "../Footer"

//Styled components imports
import Wrapper from "./Wrapper"
import Main from "./Main"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <GlobalStyle />
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  )
}

export default Layout
