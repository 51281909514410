import styled from "styled-components"

const Main = styled.main`
  flex-grow: 1;
  padding: 0 25px;
  position: relative;
  padding-bottom: 42px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 0 120px 42px;
  }
`

export default Main
