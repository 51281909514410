import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: ${props => props.theme.colors.primary.darkGrey};
  position: relative;
  z-index: 1;
`

export default Wrapper
