import styled from "styled-components"

const Wrapper = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default Wrapper
