import styled from "styled-components"

const NavLink = styled.li`
  margin: 0 24px;
  position: relative;

  &::after  {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    height: 1px;
    width: ${props => (props.active ? "100%" : "0%")};
    background-color: ${props => props.theme.colors.primary.green};
    transition: width 0.7s ease;
  }

  &:not(:last-child):hover::after {
    width: 100%;
    transition: width 0.7s ease;
  }
`

export default NavLink
