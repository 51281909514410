import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import FacebookIcon from "./FacebookIcon"
import EnvelopeIcon from "./EnvelopeIcon"

const SocialMediaIcons = () => {
  return (
    <Wrapper>
      <a
        href="https://www.facebook.com/Dolina-hotel-159036367477104/"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon />
      </a>
      <a href="mailto:hoteldolinavk@gmail.com">
        <EnvelopeIcon />
      </a>
    </Wrapper>
  )
}

export default SocialMediaIcons
