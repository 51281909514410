import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    flex-direction: row;
    align-items: center;
    order: 1;
  }
`

export default Container
