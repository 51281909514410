import React from "react"

//React component import
import SocialMediaIcons from "./SocialMediaIcons/"

//Styled components imports
import StyledFooter from "./StyledFooter"
import Wrapper from "./Wrapper"
import Container from "./Container"
import GDPRLink from "./GDPRLink"
import Text from "./Text"
import Link from "./Link"

const Footer = () => {
  return (
    <StyledFooter>
      <Wrapper>
        <Container>
          <SocialMediaIcons />
          <GDPRLink to="/ochrana-osobnych-udajov">
            Ochrana osobných údajov
          </GDPRLink>
        </Container>
        <Text marginBottom="16px">
          Designed & developed by
          <Link href="https://beyondstars.cz/"> Beyond Stars</Link>
        </Text>
        <Text copyright>© 2023 Hotel Dolina</Text>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
