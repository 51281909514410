import { Link } from "gatsby"
import styled from "styled-components"

const GDPRLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.text.darkGrey};
  margin: 16px 0 24px 0;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    margin: 0 0 0 8px;
  }
`

export default GDPRLink
