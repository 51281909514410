import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

/* CSS reset start */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, small, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
/* CSS reset end */

/* Custom global styles */
html {
    font-size: 17px;
    color: ${props => props.theme.colors.text.white};
    scroll-behavior: smooth;
}

body, input, textarea {
    font-family: "Lato", sans-serif;
    font-weight: 600;   
    letter-spacing: 0.05em;
}

h1, h2, h3, h4, h5, h6, button {
    font-family: "Raleway", sans-serif;
    letter-spacing: 0;
    font-weight: 500;
}

h1 {
    font-size: 42px;
}

h2 {
    font-size: 36px;  
}

.fresnel-container{
   display: contents
}

table{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 24px;
}

tr, th, td{
  text-align: center
}

td {
    vertical-align : middle;
}

a{
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: brightness(120%);
  }
}

`

export default GlobalStyle
