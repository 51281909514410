import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px;
  margin: auto;
  color: ${props => props.theme.colors.text.darkGrey};

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    flex-direction: row;
    max-width: 1200px;
    justify-content: space-between;
    padding: 15px 20px;
  }
`

export default Wrapper
