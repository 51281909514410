import styled from "styled-components"

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #2e2e2e;

  @media (${props => props.theme.mediaQueries.desktop}) {
    position: static;
  }

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    height: 80px;
  }
`

export default StyledHeader
