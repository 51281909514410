import styled, { css } from "styled-components"

const Text = styled.div`
  font-weight: 300;
  margin-bottom: ${props => props.marginBottom || "0"};
  text-align: center;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    order: 3;
    margin: 0;
  }

  ${props =>
    props.copyright &&
    css`
      @media (${props => props.theme.mediaQueries.largeDesktop}) {
        order: 2;
      }
    `}
`

export default Text
