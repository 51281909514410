import breakpoints from "../breakpoints"

const theme = {
  colors: {
    primary: {
      green: "#98D6CD",
      darkGrey: "#2E2E2E",
    },
    text: {
      darkGrey: "#0D0D0D",
      white: "#FFF",
    },
    imageBorder: {
      grey: "rgba(215, 221, 221, 0.7)",
    },
    red: "#E63946",
  },
  fontSizes: {
    small: "12px",
    normal: "17px",
  },
  mediaQueries: {
    tablet: breakpoints.tablet,
    desktop: breakpoints.desktop,
    largeDesktop: breakpoints.largeDesktop,
  },
}

export default theme
