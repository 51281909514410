import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  text-transform: uppercase;
  text-decoration: none;
  font-size: 18px;
  color: ${props => props.theme.colors.text.white};

  ${props =>
    props.highlighted &&
    css`
      background-color: ${props => props.theme.colors.primary.green};
      padding: 12px;
      color: ${props.theme.colors.text.darkGrey};
      margin-left: -12px;
    `}
`

export default Link
